<template>
    <div class="smart-reviews">
        <global-page-back
            detailPageTitle="智能考核"
            @handlerGlobalType="handlerGlobalType"
        />
        <div class="content-main">
            <div class="form-main-title">
                <div class="title">学生身份验证</div>
            </div>
            <div class="avatar-upload-wrap">
                <el-upload
                    ref="avatarUpload"
                    v-loading="uploadLoading"
                    :action="avatarAction"
                    :headers="headers"
                    :accept="avatarAccept.toString()"
                    :show-file-list="false"
                    :before-upload="beforeAvatarUpload"
                    :on-success="handleAvatarSuccess"
                    class="avatar-upload"
                >
                    <div
                        v-if="uploadUrl"
                        class="avatar-upload-img"
                        @click.stop
                    >
                        <img
                            :src="uploadUrl"
                            alt=""
                        />
                        <div class="avatar-upload-img-operate">
                            <i
                                class="el-icon-upload2"
                                @click.stop="handleAvatarReUpload"
                            ></i>
                            <i
                                class="el-icon-delete"
                                @click.stop="handleAvatarDelete"
                            ></i>
                        </div>
                    </div>
                    <i
                        v-else
                        class="avatar-upload-icon el-icon-plus"
                    ></i>
                </el-upload>
                <div class="tips-wrap">
                    <div class="tips-title">照片上传要求：</div>
                    <div class="tips-content">
                        1、照片用于识别学生进行德育考核使用，请保证上传的照片为面部正面照片，人脸没有其他物体遮挡；
                    </div>
                    <div class="tips-content">
                        2、如佩戴眼镜请保证眼镜框没有遮挡眼睛；
                    </div>
                    <div class="tips-content">3、请不要佩戴口罩；</div>
                    <div class="tips-content">
                        4、支持格式为JPG，JPEG，GIF，BMP或PNG，大小不超过8M。
                    </div>
                </div>
            </div>
            <div class="form-main-title">
                <div class="title">学生信息</div>
                <div v-if="tableData.length > 0" class="sub-title">
                    根据上传图像情况检索出相似度高的学生信息
                </div>
            </div>
            <table-data
                ref="table"
                :to-top="false"
                :config="table_config"
                :tableData="tableData"
            >
                <template #studentName="{data}">
                    <el-button
                        type="text"
                        @click="clickStudentName(data)"
                        class="ellipsis"
                        style="width: 100%"
                    >
                        {{ data.studentName }}
                    </el-button>
                </template>
                <template #operation="{data}">
                    <el-button
                        type="text"
                        @click="clickAddAudit(data)"
                    >
                        添加考核
                    </el-button>
                </template>
            </table-data>
        </div>
        <!-- 添加考核 -->
        <dialog-wrapper
            :dialog-obj="dialogObj"
            @handleClose="handleClose"
        >
            <div class="common-form">
                <el-form
                    ref="formRef"
                    :model="ruleForm"
                    label-width="120px"
                    label-position="right"
                    :rules="rules"
                >
                    <el-form-item
                        label="考核日期"
                        prop="assessDate"
                    >
                        <el-date-picker
                            v-model="ruleForm.assessDate"
                            type="datetime"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm"
                            clearable
                            placeholder="请选择考核日期"
                            class="input-width-medium"
                        />
                    </el-form-item>
                    <el-form-item
                        label="考核指标"
                        prop="ruleId"
                    >
                        <el-select-tree
                            v-model="ruleForm.ruleId"
                            :data="ruleList"
                            :props="{
                                value: 'id',
                                label: 'ruleName',
                                children: 'children',
                            }"
                            clearable
                            filterable
                            @change="changeRule"
                            class="input-width-medium"
                        >
                        </el-select-tree>
                    </el-form-item>
                    <el-form-item
                        label="考核行为"
                        prop="behaviorIds"
                    >
                        <el-select
                            v-model="ruleForm.behaviorIds"
                            clearable
                            filterable
                            multiple
                            @change="changeBehavior"
                            class="input-width-medium"
                        >
                            <el-option
                                v-for="(item, index) in behaviorList"
                                :label="item.ruleName"
                                :value="item.id"
                                :key="index"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="上传照片"
                        prop="img"
                    >
                        <div style="display: flex">
                            <el-upload
                                :action="action"
                                :headers="headers"
                                :multiple="true"
                                :file-list="fileList1"
                                :on-success="handleSuccess"
                                :before-upload="beforeUpload"
                                accept="image/*"
                                :show-file-list="false"
                                :limit="3"
                                :on-exceed="handleExceed"
                                class="avatar-uploader"
                            >
                                <div
                                    v-if="isUpdataImg"
                                    class="upload-wrap"
                                >
                                    <div class="upload-btn">
                                        <div class="add-icon">+</div>
                                        <div class="upload-text">上传图片</div>
                                    </div>
                                </div>
                            </el-upload>
                            <div
                                v-if="ruleForm.img.length > 0"
                                class="swiper-wrap"
                            >
                                <div
                                    v-for="(item, index) in ruleForm.img"
                                    :key="index"
                                    class="img-wrap"
                                >
                                    <div class="img_each">
                                        <img
                                            style="
                                                width: 80px;
                                                height: 80px;
                                                border: 1px solid #c0ccda;
                                            "
                                            :src="item"
                                            alt=""
                                        />
                                        <div class="del">
                                            <i
                                                class="el-icon-delete"
                                                @click.stop="
                                                    deleteSwiperImg(index)
                                                "
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item
                        :label="
                            ruleForm.ruleType === '1' ? '扣分分值' : '加分分值'
                        "
                        prop="score"
                    >
                        <el-input
                            class="input-width-medium"
                            v-model="totalScore"
                            readonly
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        label="考核说明"
                        prop="remark"
                    >
                        <el-input
                            v-model="ruleForm.remark"
                            clearable
                            rows="5"
                            type="textarea"
                            maxlength="100"
                            show-word-limit
                            placeholder="请输入考核说明"
                            class="input-width-medium"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <div style="text-align: right">
                    <xk-button @click="handleClose">取消</xk-button>
                    <xk-button
                        :loading="btnLoading"
                        type="primary"
                        @click="handleSave"
                        :disabled="disableSubmit"
                    >
                        确定
                    </xk-button>
                </div>
            </div>
        </dialog-wrapper>
    </div>
</template>

<script>
import {DialogWrapper, debounce} from "common-local";
import TableData from "../Sub/TableData";
import ElSelectTree from "el-select-tree";
import {mapState} from "vuex";
import {formatTreeData, treeFind} from "@/libs/utils";
import {getToken} from "@/libs/auth";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
import onResize from "@/mixins/onResize";
import RecordsList from "@/libs/pageListOptimal";

export default {
    name: "SmartReviews",
    mixins: [onResize],
    components: {
        GlobalPageBack,
        TableData,
        DialogWrapper,
        ElSelectTree,
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
        action() {
            return "/api/school/common/uploadFile";
        },
        avatarAction() {
            return "/api/school/schoolMoralStudentDetail/getStudentIdsByFace";
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
        recordsKey() {
            return `${this.$route.name}SmartReviews`;
        },
    },
    data() {
        return {
            uploadLoading: false,
            avatarAccept: [".jpg", ".jpeg", ".bmp", ".gif", ".png"],
            uploadUrl: "",
            btnLoading: false,
            listQuery: {
                ids: [],
            },
            loadingTable: false,
            table_config: {
                thead: [
                    {
                        type: "slot",
                        slotName: "studentName",
                        fixed: false,
                        label: "学生姓名",
                        prop: "studentName",
                        align: "center",
                        tooltip: true,
                    },
                    {
                        label: "年级",
                        prop: "gradeName",
                        align: "center",
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "班级",
                        prop: "className",
                        align: "center",
                        className: "text-spacing-reduction",
                    },
                    {
                        label: "学号",
                        prop: "studyNo",
                        align: "center",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "唯一号",
                        prop: "onlyCode",
                        align: "center",
                    },
                    {
                        label: "批评记录",
                        prop: "breakRuleNum",
                        align: "center",
                    },
                    {
                        label: "表扬记录",
                        prop: "honorNum",
                        align: "center",
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        labelWidth: "150",
                        className: "text-spacing-reduction",
                    },
                ],
                height: "",
                check: false,
            },
            tableData: [],
            isUpdataImg: true,
            dialogObj: {
                title: "添加考核",
                width: "668px",
                dialogVisible: false,
            },
            ruleForm: {
                schoolId: "",
                classId: "",
                gradeId: "",
                onlyCode: "",
                studentId: "",
                assessDate: "",
                ruleId: "",
                behaviorIds: [],
                img: [],
                score: "",
                remark: "",
                ruleType: "",
            },
            totalScore: 0,
            rules: {
                assessDate: [],
                ruleId: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核指标",
                    },
                ],
                behaviorIds: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核行为",
                    },
                ],
                score: [
                    {
                        pattern: /^(\d+\.\d{1,1}|\d+)$/,
                        message: "分值最多支持一位小数点",
                    },
                ],
            },
            ruleList: [],
            behaviorList: [],
            fileList1: [],
            schoolCalendar: [],
            currentCalendar: [],
            disableSubmit: false,
        };
    },
    created() {
        this.initBaseData();
    },
    methods: {
        beforeAvatarUpload(file) {
            let fileType = file.name.split(".").pop();
            const isImage = this.avatarAccept.includes(`.${fileType}`);
            const isLt20M = file.size / 1024 / 1024 < 8;
            const is50 = file.name.length < 50;
            if (!is50) {
                this.$message.error("上传图片名称不能超过50个字符!");
                return false;
            }
            if (!isImage) {
                this.$message.error("只允许上传图片!");
                return false;
            }
            if (!isLt20M) {
                this.$message.error("上传图片大小不能超过 8MB!");
                return false;
            }
            this.uploadLoading = true;
        },
        handleAvatarReUpload() {
            this.$refs.avatarUpload.$refs['upload-inner'].handleClick()
        },
        handleAvatarSuccess(res) {
            if (res.code === "200") {
                if (res.data.studentIds) {
                    this.listQuery.ids = res.data.studentIds;
                    this.getList();
                } else {
                    this.handleAvatarDelete()
                    this.$message.error('未查到该学生信息，请重新上传清晰人脸照')
                }
                this.uploadUrl = res.data.fileUrl || ''
            } else {
                this.handleAvatarDelete()
                this.$message.error(res.msg);
            }
            this.uploadLoading = false;
        },
        handleAvatarDelete() {
            this.listQuery.ids = []
            this.tableData = [];
            this.uploadUrl = "";
        },
        /**
         * @Description: 自定义校验考核日期
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 邵澳
         * @Date: 2023/8/2 15:44
         */
        checkExamineDate: debounce(function (rule, value, callback) {
            if (!value) {
                return callback(new Error("请选择考核日期"));
            }

            //调接口判断是否能添加考核
            this.judegIsExamine().then((res) => {
                if (res.data.code == "200") {
                    if (res.data.data.status == "1") {
                        this.disableSubmit = false;
                        return callback();
                    } else {
                        this.disableSubmit = true;
                        return callback(
                            new Error("学生在该时间段已请假，无法进行考核"),
                        );
                    }
                }
            });
        }),
        /**
         * @Description: 判断该学校请假时是否允许考核，不允许的话再判断该学生在选中的考核时间内是否请假了，请假了的话不能考核，接口去判断
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 邵澳
         * @Date: 2023/8/2 15:44
         */
        judegIsExamine() {
            return new Promise((resolve) => {
                const {assessDate, schoolId, studentId} = this.ruleForm;
                this._fet("/school/schoolMoralStudentDetail/verify", {
                    assessDate,
                    schoolId,
                    studentId,
                }).then((res) => {
                    resolve(res);
                });
            });
        },
        async initBaseData() {
            this.getRuleList();
            this.rules.assessDate.push({
                validator: this.checkExamineDate,
                trigger: "change",
            });
            let recordsList = new RecordsList();
            if (recordsList.get(this.recordsKey)) {
                this.uploadUrl = recordsList.get(this.recordsKey).uploadUrl;
                this.listQuery = recordsList.get(this.recordsKey).listQuery;
                this.getList()
            }
        },
        // 获取表格数据
        getList() {
            this.loadingTable = true;
            this._fet(
                "/school/schoolMoralStudentDetail/getMoralListByIds",
                this.listQuery,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.tableData = res.data.data;
                    this.$nextTick(() => {
                        let recordsList = new RecordsList();
                        if (this.$refs.table && recordsList.get(this.recordsKey)) {
                            this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop =
                                recordsList.get(this.recordsKey).scrollTop || 0;
                            recordsList.remove(this.recordsKey);
                        }
                        this.loadingTable = false;
                    });
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                    this.loadingTable = false;
                }
            });
        },
        getRuleList() {
            this._fet("/school/schoolMoralRule/listByCondition", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    this.ruleList = formatTreeData(
                        res.data.data,
                        "id",
                        "parentId",
                    );
                }
            });
        },
        /**
         * @Description: 打开添加考核行为弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/4 15:51
         */
        clickAddAudit(data) {
            this.isUpdataImg = true;
            this.dialogObj.dialogVisible = true;
            const keyList = ["studentId", "gradeId", "classId", "onlyCode"];
            keyList.forEach((item) => {
                this.ruleForm[item] = data[item];
            });
            this.ruleForm.assessDate =
                this.$moment().format("YYYY-MM-DD HH:mm");
            this.$nextTick(() => {
                this.$refs.formRef.validateField(["assessDate"], (err) => {
                    console.log("err=====", err);
                });
            });

            this.ruleForm.schoolId = this.schoolId;
        },
        /**
         * @Description: 选择考核指标，获取考核行为数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/4 15:50
         */
        changeRule(data) {
            this.behaviorList = [];
            this.ruleForm.ruleType = "";
            this.ruleForm.behaviorIds = [];
            this.totalScore = 0;
            const current = treeFind(this.ruleList, (x) => x.id === data);
            if (current) {
                this.ruleForm.ruleType = current.ruleType;
                this.behaviorList = current.behaviorList;
            }
        },
        changeBehavior(data) {
            this.totalScore = 0;
            console.log(
                "this.ruleForm.behaviorId111111",
                this.ruleForm.behaviorIds,
                data,
            );
            let chooseBehaviorList = [];
            data.forEach((items) => {
                let find = this.behaviorList.find((i) => i.id === items);
                chooseBehaviorList.push(find);
            });
            if (chooseBehaviorList.length > 0) {
                chooseBehaviorList.forEach((iv) => {
                    this.totalScore += Number(iv.score);
                });
            } else {
                this.totalScore = 0;
            }
        },
        /**
         * handleExceed  当上传的文件超出限制时
         * beforeUpload  上传校验
         */
        //限制传文件个数
        handleExceed() {
            this.$message.warning("最多选择 3 张图片");
        },
        deleteSwiperImg(index) {
            this.fileList1.splice(index, 1);
            this.ruleForm.img.splice(index, 1);
            if (this.ruleForm.img.length < 3) {
                this.isUpdataImg = true;
            }
        },
        /**
         * @Description: 上传校验
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/4 15:51
         */
        beforeUpload(file) {
            const isImage = file.type.split("/")[0] === "image";
            const isLt20M = file.size / 1024 / 1024 < 20;
            const is50 = file.name.length < 50;
            if (!is50) {
                this.$message.error("上传图片名称不能超过50个字符!");
                return false;
            }
            if (!isImage) {
                this.$message.error("只允许上传图片!");
                return false;
            }
            if (!isLt20M) {
                this.$message.error("上传图片大小不能超过 20MB!");
                return false;
            }
        },
        /**
         * @Description: 上传功能回调
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/4 15:51
         */
        handleSuccess(res) {
            if (res.code === "200") {
                this.ruleForm.img.push(res.data.url);
                if (this.ruleForm.img.length >= 3) {
                    this.isUpdataImg = false;
                }
            }
        },
        /**
         * @Description: 添加考核行为
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/4 15:51
         */
        handleSave() {
            if (!this.ruleForm.gradeId || !this.ruleForm.classId) {
                this.$message.warning(
                    "该学生缺少年级班级信息，请补充后再添加考核",
                );
                return;
            }
            this.$refs.formRef.validate((val) => {
                if (val) {
                    this.btnLoading = true;
                    let form = JSON.parse(JSON.stringify(this.ruleForm));
                    form.img = form.img.toString();
                    form.score = this.totalScore;
                    this._fet("/school/schoolMoralStudentDetail/save", form)
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("添加考核成功");
                                this.handleClose();
                                this.getList();
                                this.fileList1 = [];
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                            this.btnLoading = false;
                        })
                        .catch(() => {
                            this.btnLoading = false;
                        });
                }
            });
        },
        /**
         * @Description: 关闭添加考核行为弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 韩熙昊
         * @Date: 2023/4/4 15:51
         */
        handleClose() {
            this.dialogObj.dialogVisible = false;
            this.ruleForm = this.$options.data.call(this).ruleForm;
            this.behaviorList = [];
            this.fileList1 = [];
            this.$nextTick(() => {
                this.$refs.formRef.clearValidate();
            });
        },
        // 档案详情
        clickStudentName(data) {
            //存储筛选项及滚动条高度
            let recordsList = new RecordsList();
            recordsList.set(this.recordsKey, {
                uploadUrl: this.uploadUrl,
                listQuery: this.listQuery,
                scrollTop:
                    this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop,
            });
            let sexMap = {
                1: "男",
                2: "女",
            };
            this.$emit("changeComp", {
                name: "ArchivesManage",
                data: {
                    ...data,
                    sexName: sexMap[+data.sex],
                    sourceType: "SmartReviews",
                },
            });
        },
        handlerGlobalType() {
            this.$emit("changeComp", {
                name: "AssessmentList",
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.swiper-wrap {
    display: flex;
    flex-wrap: wrap;
}

.img_each {
    width: 80px;
    height: 80px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    border: 1px solid #c0ccda;
    margin-right: 10px;

    img {
        object-fit: cover;
    }

    .del {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        opacity: 0;

        &:hover {
            opacity: 1;
        }

        .el-icon-delete {
            font-size: 20px;
            color: #ffffff;
        }
    }
}

.upload-wrap {
    display: flex;
    align-items: center;
}

// 图片数量等于限制数量时隐藏上传组件
::v-deep.hide {
    .el-upload--avatar-uploader {
        display: none !important;
    }
}

.upload-btn {
    text-align: center;
    width: 80px;
    height: 80px;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 8px;

    .add-icon {
        margin-top: 24px;
        font-size: 20px;
        line-height: 20px;
        color: #c2c8cc;
    }

    .upload-text {
        margin-top: 15px;
        line-height: 12px;
        font-size: 12px;
        color: #999999;
    }
}

.global-page-back {
    box-shadow: 1px 1px 2px 1px #e1e3e6;
    z-index: 99;
}

.content-main {
    background-color: #fff;
    padding: 20px;
}

.avatar-upload-wrap {
    display: flex;
    margin-bottom: 40px;
    padding-left: 80px;

    .avatar-upload {
        flex-shrink: 0;
        align-self: flex-start;
        width: 80px;
        height: 80px;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        text-align: center;
        overflow: hidden;

        ::v-deep .el-upload--text {
            width: 80px;
            height: 80px;
        }

        .avatar-upload-img {
            position: relative;
            width: 80px;
            height: 80px;

            img {
                width: 100%;
                height: 100%;
            }

            .avatar-upload-img-operate {
                display: flex;
                cursor: default;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                background: rgba(0, 0, 0, 0.5);
                transition: opacity 0.3s;

                .el-icon-upload2,
                .el-icon-delete {
                    cursor: pointer;
                    color: #ffffff;
                    margin: 0 5px;
                    font-size: 18px;
                }
            }

            &:hover .avatar-upload-img-operate {
                opacity: 1;
            }
        }

        &-icon {
            font-size: 16px;
            color: #d9d9d9;
            line-height: 80px;
            text-align: center;
        }
    }

    .tips-wrap {
        margin-left: 24px;

        .tips-title {
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            margin-bottom: 5px;
        }

        .tips-content {
            font-size: 12px;
            color: #999999;
            line-height: 20px;
        }
    }
}

.form-main-title {
    height: 38px;
    display: flex;
    align-items: center;
    background: #f7f8fa;
    margin-bottom: 20px;

    &::before {
        content: "";
        width: 3px;
        height: 14px;
        background: #3c7fff;
        border-radius: 2px;
        margin-left: 23px;
        margin-right: 6px;
    }

    .title {
        font-size: 16px;
        font-weight: bold;
        color: #2b2f33;
    }

    .sub-title {
        margin-left: 10px;
        font-size: 14px;
        color: #999999;
    }
}
</style>