<template>
    <div class="back-wrap">
        <div @click="clickBack" class="back-left">
            <i class="el-icon-back"></i>
            <div class="back-text">返回</div>
        </div>
        <template v-if="title">
            <div class="back-split">|</div>
            <div class="back-title">{{ title }}</div>
        </template>
    </div>
</template>

<script>
export default {
    name: "BackNav",
    props: {
        title: {
            type: String,
            default: ""
        }
    },
    methods: {
        clickBack() {
            this.$emit('back')
        }
    }
}
</script>

<style scoped lang="scss">
.back-wrap {
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 48px;
    padding: 0 16px;

    .back-left {
        display: flex;
        align-items: center;
        cursor: pointer;

        .el-icon-back {
            line-height: 12px;
            font-size: 12px;
            color: #808387;
            margin-top: 3px;
        }

        .back-text {
            white-space: nowrap;
            width: 27px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2B2F33;
            margin-left: 6px;
        }

        &:hover {
            .el-icon-back, .back-text {
                color: #3C7FFF
            }
        }

    }

    .back-split {
        width: 1px;
        color: #E1E3E6;
        padding: 0 20px;
    }

    .back-title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2B2F33;
    }
}
</style>
